import type { PayMethod } from '@b-stock/payments-methods-api-client'

import appConfig from '@config/config'
import { get, deleteReq, post } from '@helpers/xhr'

const { api, appName, env, platform } = appConfig

export default {
  async createBankAccountPaymentMethod(values: any, { stripe }: any) {
    const {
      author,
      accountHolderName,
      accountHolderType,
      accountNumber,
      entityId,
      routingNumber,
    } = values
    const { error, source } = await stripe.createPaymentMethod({
      type: 'us_bank_account',
      billing_details: {
        name: accountHolderName,
      },
      us_bank_account: {
        account_holder_type: accountHolderType,
        account_number: accountNumber,
        routing_number: routingNumber,
      },
      metadata: {
        entity_id: entityId,
        testedUsing: appName,
        author: author?.trim() || '',
        stripe_env: env,
        platform,
      },
    })

    if (error) {
      throw new Error(error)
    }

    return source
  },

  async fetchPaymentMethods({
    params = {},
    entityId,
  }: {
    params: { [key: string]: string }
    entityId: string
  }) {
    const { data } = await get<PayMethod[]>(api.payments.entities, params, {
      entities: { entityId },
    })
    return data
  },

  async deletePaymentMethod({ id }: { id: string }) {
    const { data } = await deleteReq<any>(api.payments.methods, undefined, {
      entities: { id },
    })
    return data
  },

  async setPaymentMethodAsDefault({ id }: { id: string }) {
    const { data } = await post<any>(api.payments.makePrimary, undefined, {
      entities: { id },
    })
    return data
  },

  async fetchBstockCredit({ accountId }: { accountId: string }) {
    const { data } = await get<any>(api.payments.credit.balance, null, {
      entities: { accountId },
    })
    return data
  },
}
