const authUrl = process.env.SERVICE_HOST_FUSIONAUTH
const accountsApiUrl = process.env.SERVICE_HOST_ACCOUNTS
const docservApiUrl = process.env.SERVICE_HOST_DOCSERV
const locationApiUrl = process.env.SERVICE_HOST_LOCATIONS
const paymentsMethodsUrl = process.env.SERVICE_HOST_PAYMETHODS || ''
const bapiUrl = process.env.SERVICE_HOST_BAPI
const bstockCreditsUrl = process.env.SERVICE_HOST_BSTOCK_CREDIT
const ordersApiUrl = process.env.SERVICE_HOST_ORDERS
const clientId = process.env.FA_CLIENT_ID
const bridge = process.env.SERVICE_HOST_BRIDGE

// set notify api url by environment injection OR use known b-stock dev ops hostname
const notificationsUrl = process.env.SERVICE_HOST_NOTIFICATIONS
// relates to what type of marketing source is being applied for (acceptMarketingSolicitation)
const marketingTypeId = process.env.NOTIFICATION_TYPE_ID
// Fallback when NOTIFICATION_TYPE_ID undefined. Can occur during local developments so just helpful. Does not need to be devops reported
const marketingEmailTypeName =
  process.env.NOTIFICATION_EMAIL_ME_ABOUT_AUCTIONS || 'EMAIL_ME_ABOUT_AUCTIONS'

const oauth2 = `${authUrl}/oauth2`

const people = `${accountsApiUrl}/people`
const peopleId = `${people}/:id`

const accounts = `${accountsApiUrl}/accounts`
const accountsId = `${accountsApiUrl}/accounts/:id`
const accountsIdRole = `${accountsApiUrl}/accounts/:id/roles/:role`

const askMe = `${accountsApiUrl}/ask-me-tokens`

const docserv = `${docservApiUrl}/v1/documents`
const locations = `${locationApiUrl}/locations`
const exemptionReqs = `${locationApiUrl}/exemption-requirements`
const auctionsList = 'https://bstock.com/all-auctions/'
const allMarketplaces = 'https://bstock.com/marketplaces/'

const sites = `${accountsApiUrl}/sites`

export default {
  portalPaths: {
    auctionsList,
    allMarketplaces,
  },
  oauth2: {
    authorize: `${oauth2}/authorize`,
    logout: `${oauth2}/logout`,
    forgot: `${authUrl}/password/forgot`,
    refresh: `${authUrl}/api/jwt/refresh`,
  },
  salesforce: {
    authorize: process.env.SALESFORCE_AUTH_URL,
    clientId: process.env.SALESFORCE_AUTH_CLIENT_ID,
    refresh: `${accountsApiUrl}/oauth2/token/salesforce`,
  },
  health: {
    check: '/health-check',
    ping: '/ping',
  },

  auth: {
    clientId,
    tokenExpirationTime: 3540, // dispatch action after 59 min, not 60
  },

  bapi: {
    buildDoc: `${bapiUrl}/registration/users/build-doc`,
  },

  corpSite: {
    terms: 'https://bstock.com/terms-of-use',
  },

  notifications: {
    marketingEmailTypeName, // relates to getEmailNotificationTypeId()
    marketingTypeId, // relates to acceptMarketingSolicitation:boolean and updateMarketingPreference()
    preferences: `${notificationsUrl}/preferences`,
    types: `${notificationsUrl}/types`,
    markets: `${notificationsUrl}/markets`,
    favoriteMarkets: `${notificationsUrl}/favorite-markets`,
  },

  people: {
    default: people,
    faLogin: `${people}/login`,
    sfLogin: `${people}/login/salesforce`,
    logout: `${people}/logout`,
    forgotPassword: `${people}/forgot-password`,
    checkExisting: `${people}/check-existing`,

    id: {
      default: peopleId,
      resendValidationEmail: `${peopleId}/resend-validation-email`,
      setPassword: `${peopleId}/set-password`,
      verifyEmail: `${peopleId}/verify-email`,
      verifyEmailCode: `${peopleId}/verify-email-code`,
    },
  },

  bridge: {
    uaExists: `${bridge}/ua-exists`,
    mageExists: `${bridge}/mage-exists`,
    accountsMigrate: `${bridge}/accounts/migrate`,
  },

  accounts: {
    default: accounts,
    id: {
      default: accountsId,
      roles: {
        default: `${accountsId}/roles`,
        accountsIdRole: {
          default: accountsIdRole,
          onboarding: `${accountsIdRole}/onboarding`,
          status: `${accountsIdRole}/status`,
        },
      },
      requirements: {
        siteAbb: `${accounts}/:id/requirements/:siteAbb`,
      },
    },
    sites: {
      getSites: sites,
      addMarketplace: `${accounts}/:id/sites`,
    },
  },

  locations: {
    default: locations,
    document: `${locationApiUrl}/documents`,
    documentById: `${locationApiUrl}/documents/:id`,
    taxIdValidate: `${locationApiUrl}/documents/validate/:state`,
    validate: `${locationApiUrl}/addresses/validate`,
    id: {
      default: `${locations}/:id`,
    },
    exemptionReqs: {
      country: `${exemptionReqs}/:country`,
      countryRegion: `${exemptionReqs}/:country/:region`,
    },
    generateResaleCertificate: `${locationApiUrl}/documents/tax-document`,
    countries: `${locationApiUrl}/countries`,
    exemption: `${locationApiUrl}/exemptions`,
    exemptionByJurisdiction: `${locationApiUrl}/exemptions/:accountId/:country/:region`,
  },

  docserv: {
    default: docserv,
  },

  payments: {
    entities: `${paymentsMethodsUrl}/entities/:entityId/methods`,
    methods: `${paymentsMethodsUrl}/methods/:id`,
    makePrimary: `${paymentsMethodsUrl}/methods/:id/make-primary`,
    credit: {
      balance: `${bstockCreditsUrl}/erp/credits-balance/:accountId`,
      createACHTransferAccount: `${paymentsMethodsUrl}/entities/:accountId/stripe/ach-credit-transfer`,
      pay: {
        'bstock-credit': `${ordersApiUrl}/:orderId/pay/credit-balance`,
        split: `${ordersApiUrl}/:orderId/pay/split-pay`,
      },
    },
    createStripeFinancialSession: `${paymentsMethodsUrl}/entities/:entityId/stripe/financial-session`,
    createSetupIntent: `${paymentsMethodsUrl}/entities/:entityId/stripe/setup-intent`,
    updateStripeCustomer: `${paymentsMethodsUrl}/entities/:entityId/stripe/customers`,
  },

  askMe: {
    default: askMe,
  },

  serviceHosts: {
    paymentMethods: paymentsMethodsUrl,
  },
} as const
