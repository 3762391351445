import type { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios'
import axios from 'axios'
import reduce from 'lodash-es/reduce'

import { getAccessToken } from '@helpers/accessToken'

type EntitiesMap = Partial<Record<string, string>>

function applyEntities(url: string, data?: EntitiesMap) {
  if (!data) {
    return url
  }

  return reduce(
    data,
    (str, value, key) => (value ? str.replace(`:${key}`, value) : str),
    url
  )
}

type CommonApiCallOptions = Omit<
  AxiosRequestConfig,
  'method' | 'data' | 'params' | 'headers'
> & {
  params?: AxiosRequestConfig['params']
  entities?: EntitiesMap
  headers?: Partial<RawAxiosRequestHeaders>
}

async function apiCall<T>(
  url = '',
  data: AxiosRequestConfig['data'] = null,
  {
    method = 'GET',
    params,
    headers: additionalHeaders,
    responseType = 'json',
    entities,
    ...additionalOptions
  }: CommonApiCallOptions & {
    method?: AxiosRequestConfig['method']
  } = {}
) {
  const accessToken = await getAccessToken()
  const preparedUrl = applyEntities(url, entities)

  const headers = {
    'Content-Type': 'application/json',
    // comment out line 32-42 as workaround when running BE locally
    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    ...additionalHeaders,
  }

  const config = {
    method, // *GET, POST, PUT, DELETE, etc.
    // mode: 'no-cors', // set as no-cors as workaround when running BE locally
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    responseType, // json, arraybuffer, document, text, stream
    params,
    ...(data ? { data } : {}), // body data type must match 'Content-Type' header
    ...additionalOptions,
  }

  return axios<T>(preparedUrl, config)
}

export function get<T>(
  url: string,
  params?: AxiosRequestConfig['params'],
  options?: Omit<CommonApiCallOptions, 'params'>
) {
  return apiCall<T>(url, undefined, { params, ...options })
}

export function post<T>(
  url: string,
  data: AxiosRequestConfig['data'] = {},
  options?: CommonApiCallOptions
) {
  return apiCall<T>(url, data, { method: 'POST', ...options })
}

export function patch<T>(
  url: string,
  data: AxiosRequestConfig['data'] = {},
  options?: CommonApiCallOptions
) {
  return apiCall<T>(url, data, { method: 'PATCH', ...options })
}

export function put<T>(
  url: string,
  data: AxiosRequestConfig['data'] = {},
  options?: CommonApiCallOptions
) {
  return apiCall<T>(url, data, { method: 'PUT', ...options })
}

export function deleteReq<T>(
  url: string,
  data: AxiosRequestConfig['data'] = {},
  options?: CommonApiCallOptions
) {
  return apiCall<T>(url, data, { method: 'DELETE', ...options })
}
