import type {
  TaxExemptionOutput as ExemptionsResponse,
  TaxExemptionModel as Exemption,
  ExemptionsApiGetExemptionsRequest,
} from '@b-stock/location-api-client'

import appConfig from '@config/config'
import { get, post } from '@helpers/xhr'

const { api } = appConfig

type ByJurisdictionParams = {
  accountId: string
  country: string
  region?: string
  sourceExemption?: string | null
}

const composeByJurisdictionUrl = ({
  accountId,
  country,
  region,
  sourceExemption,
}: ByJurisdictionParams) => {
  const base = `${api.locations.exemption}/${encodeURIComponent(
    accountId
  )}/${encodeURIComponent(country)}`
  const withRegion = region ? `${base}/${encodeURIComponent(region)}` : base
  return sourceExemption
    ? `${withRegion}?sourceExemptionId=${sourceExemption}`
    : withRegion
}

export default {
  async getTaxExemptions(params?: ExemptionsApiGetExemptionsRequest) {
    const { data } = await get<ExemptionsResponse>(
      api.locations.exemption,
      params
    )
    return data
  },
  async getTaxExemptionByJurisdiction(params: ByJurisdictionParams) {
    const { data } = await get<Exemption>(composeByJurisdictionUrl(params))
    return data
  },
  async createTaxExemption(params: ByJurisdictionParams) {
    const { data } = await post<Exemption>(composeByJurisdictionUrl(params), {})
    return data
  },
}
